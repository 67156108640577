//
// Paths
//
$assetsRootPath: '/assets/';
$leftArrow: '/assets/ManageMyMembership/left_arrow.svg';
$rightArrow: '/assets/ManageMyMembership/right_arrow.svg';

//
// Assets
//
$headerBackgroundImageAsset: '#{$assetsRootPath}header_background.png';
$bannerGoldImageAsset: '#{$assetsRootPath}/banner_gold.png';
$bannerSilverImageAsset: '#{$assetsRootPath}/banner_silver.png';
$bannerBronzeImageAsset: '#{$assetsRootPath}/banner_bronze.png';
// $sparkleGoldImageAsset: '#{$assetsRootPath}/sparkle_gold.svg';
// $sparkleSilverImageAsset: '#{$assetsRootPath}/sparkle_silver.svg';
// $sparkleBronzeImageAsset: '#{$assetsRootPath}/sparkle_bronze.svg';
// $sparklePlatinumImageAsset: '#{$assetsRootPath}/sparkle_platinum.svg';
$bubblesImageAsset: '#{$assetsRootPath}/bubbles.svg';
$unitedDroplets: '#{$assetsRootPath}/united_droplets.svg';

$bannerLines: '#{$assetsRootPath}/banner_lines.png';

$sparkleGoldImageAsset: '#{$assetsRootPath}/Tier_stars/sparkle_gold.svg';
$sparkleSilverImageAsset: '#{$assetsRootPath}/Tier_stars/sparkle_silver.svg';
$sparkleBronzeImageAsset: '#{$assetsRootPath}/Tier_stars/sparkle_bronze.svg';
$sparklePlatinumImageAsset: '#{$assetsRootPath}/Tier_stars/sparkle_platinum.svg';

$iconStar: '#{$assetsRootPath}/ManageMyMembership/Pass_Feature_icons/Basic_Icons/Star_Basic.svg';
$iconPet: '#{$assetsRootPath}/ManageMyMembership/Pass_Feature_icons/Basic_Icons/Pet_Basic.svg';
$iconSelfServe: '#{$assetsRootPath}/ManageMyMembership/Pass_Feature_icons/Basic_Icons/Self_Serve_Wash_Basic.svg';
$iconVaccum: '#{$assetsRootPath}/ManageMyMembership/Pass_Feature_icons/Basic_Icons/Vacuum_Basic.svg';
$iconVendingMachine: '#{$assetsRootPath}/ManageMyMembership/Pass_Feature_icons/Basic_Icons/Vending_Machine_Basic.svg';
$iconAirCompressor: '#{$assetsRootPath}/ManageMyMembership/Pass_Feature_icons/Basic_Icons/Air_Compressor_Basic.svg';

//
// Colors
//
$platinum: #2b2b2b;
$platinum2: #000000;
$gold: #ff7d17;
$gold2: #c2651e;
$silver: #2A575B;
$silver2: #2c4c4e;
$bronze: #752B1C;
$bronze2: #612d22;
$orange: #ff6a00; // very similar to $gold
$black: #000000;
$gray: #777777;
$white: #ffffff;
$skyblue: #87ceeb;


$noCustomerBackgroundLandingHeader: #CA252C;

$input-border-color: $gray;
$active-input-highlight-color: $skyblue;



//
// Bootstrap Variables
//
$btn-line-height: 1.5em;

.no-customer-header {
    background-color: $noCustomerBackgroundLandingHeader;
}