.pass-purchase-page-header {
    text-align: center !important;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    line-height: 1.2;
    font-weight: 500;
}

.pass-purchase-page-body {
    text-align: center;
    input {
        max-width: 200px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
}
.pass-purchase-page-body-details {
    text-align: justify;
    input {
        max-width: 200px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
}

.pass-purchase-page-footer {
    padding: 2rem 0rem;
    display: flex;
    justify-content: space-around;
    height: calc(4rem + 1.5rem + 6px + 2px); // vertical padding + input line height + input vertical padding + input vertical border
    input {
        width: 80px;
    }
}